/* TeacherProfile.css */
.teacher-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
}

.teacher-profile-header {
  color: #005A9C; /* A shade of blue from the logo */
  text-align: center;
  margin-bottom: 20px;
}

.teacher-profile-table {
  margin-bottom: 40px;
  border-collapse: collapse;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.teacher-profile-table th,
.teacher-profile-table td {
  border: 1px solid #ddd;
  padding: 8px 16px;
  text-align: center;
}

.teacher-profile-table th {
  background-color: #007BFF; /* A darker shade of blue for the header */
  color: white;
}

.teacher-profile-table tr:nth-child(even){background-color: #f2f2f2;}
.teacher-profile-table tr:hover {background-color: #ddd;}

.teacher-profile-table button {
  padding: 8px 16px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 5px;
}

.teacher-profile-table button:hover {
  background-color: #005A9C; /* A slightly darker blue on hover */
}

.teacher-button
{
  padding: 8px 16px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.teacher-button button:hover {
  background-color: #005A9C; /* A slightly darker blue on hover */
}

/* Styles for the section containing the dropdown */
.section {
  margin: 20px 0;
}

/* Styles for the dropdown itself */
.section select {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px; /* Optional: for rounded corners */
  background-color: white;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  cursor: pointer;
}

/* Styles for the dropdown options */
.section option {
  padding: 8px 16px;
}

/* Styles for the label of the dropdown */
.section label {
  margin-right: 10px;
  font-size: 18px;
  color: #333; /* Dark gray for better readability */
}

/* Additional button styles within the section if needed */
.section button {
  padding: 8px 16px;
  border: none;
  background-color: #007BFF;
  color: white;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.section button:hover {
  background-color: #005A9C;
}

.backSection{
    display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


.backButton{
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

}
