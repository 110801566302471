.warning-message {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }