/* StudentProfile.css */
.student-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
}

.student-profile-header {
  color: #005A9C; /* A shade of blue from the logo */
  text-align: center;
  margin-bottom: 20px;
}

.student-profile-table {
  margin-bottom: 40px;
  border-collapse: collapse;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.student-profile-table th,
.student-profile-table td {
  border: 1px solid #ddd;
  padding: 8px 16px;
  text-align: center;
}

.student-profile-table th {
  background-color: #007BFF; /* A darker shade of blue for the header */
  color: white;
}

.student-profile-table tr:nth-child(even){background-color: #f2f2f2;}
.student-profile-table tr:hover {background-color: #ddd;}

.student-profile-table button {
  padding: 8px 16px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.student-profile-table button:hover {
  background-color: #005A9C; /* A slightly darker blue on hover */
}

.backSection{
    display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.backButton{
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

}
