.hamburger-icon {
    cursor: pointer;
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 20px;      
    right: 20px;    
    z-index: 1000;
    position: fixed;
}

.hamburger-icon div {
    height: 3px;
    background: black;
    border-radius: 3px;
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.menu ul {
    list-style: none;
    padding: 0;
}

.menu li {
    margin-bottom: 10px;
}

.menu-button
{
  padding: 8px 16px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.menu-button button:hover {
  background-color: #005A9C; /* A slightly darker blue on hover */
}

