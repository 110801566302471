  /* DisplayQuestions.css */

.question-display-overlay {
  display: none; /* Initially don't display */
  position: fixed;
  left: 50%; /* Centered horizontally */
  bottom: 50px; /* Height of the footer */
  transform: translateX(-50%) translateY(-40%); /* Adjust for center alignment */
  z-index: 100; /* High enough to be over other elements */
}

.question-display-overlay.open {
  display: block; /* Display when it should be visible */
}

.question-display-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  width: 60%; /* Adjust the width as needed */
  max-width: 500px; /* Adjust max-width as needed */
  transform: translateY(50); /* Slide up to show */
  transition: transform 0.3s ease-out;
}
.question-display-content-review {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  width: 100%; /* Adjust the width as needed */
  transform: translateY(50); /* Slide up to show */
  transition: transform 0.3s ease-out;
}
/* Close button for the overlay */
.close-display-questions {
  float: right;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5rem;
}



/* Styles for the button to navigate to the review page */
.go-to-review-button {
  padding: 10px 20px;
  background-color: #28a745; /* Green background */
  color: white; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; /* Space from the question buttons */
}

.review {
    display: flex;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  align-self: center;
}
/* Additional styles for the question navigation (e.g., current, unanswered, for review indicators) */
/* ... */

