.Exam-Start-Body {
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh;
  overflow: auto;
}

.content-wrapper {
  width: 100%;
  max-width: 800px;
  text-align: center; /* Center text */
}

.instruction-box {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  margin-top: 20px; /* Add some space above the button */
}

.button-container button {
  background-color: #0074d9; /* Blue background color */
  color: #fff; /* White text color */
  padding: 12px 30px; /* Increase padding for a bigger button */
  border: none;
  border-radius: 50px; /* Make the button more oval */
  cursor: pointer;
}

