/* LoginForm.css */

.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  background-color: #f2f2f2; /* Light gray background */
}

.login-form {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-form input[type="text"],
.login-form input[type="password"] {
  display: block;
  margin: 10px auto;
  width: 80%; /* Or a fixed width, e.g., 300px */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* To include padding in the width */
}

.login-form button {
  display: block;
  margin: 20px auto;
  padding: 10px 30px;
  border: none;
  background-color: #007BFF; /* Blue background */
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #005A9C; /* Darker blue on hover */
}

.login-form button:active {
  transform: scale(0.98); /* Slightly shrink buttons when clicked */
}

