.box {
    border: 2px solid #000000; /* Solid black border */
    padding: 10px; /* Space between the border and the content inside the div */
    margin: 10px; /* Optional: Space outside the border */
    border-radius: 5px; /* Optional: Rounded corners for the border */
    background-color: white;
}

.box.correct {
    background-color: #00fff3;
}

.box.incorrect {
    background-color: #E4239D;
}

.choice {
    font-weight: bold;
}

.results-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allows items to wrap to the next line if there's not enough space */
    margin-left: 10px;
    margin-bottom: 10px;
}

.section-container {
    margin-right: 20px; /* Adds some space between sections */
}

.module-container {
    margin-bottom: 10px; /* Adds some space between modules */
}

.filter-controls {
	 display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    margin-left:10px;
    }
.label-space {
	margin-right:10px;	
}

.number-box {
  width: 30px;      /* Specify the width of the box */
  height: 30px;     /* Specify the height of the box, making it a square */
  background-color: #3498db;  /* Background color of the box */
  color: white;      /* Color of the number/text inside the box */
  display: flex;     /* Using flexbox to center content */
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
  font-size: 18px;   /* Size of the number/text */
  font-weight: bold; /* Make the number/text bold */
}
