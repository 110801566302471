.break-screen {
  color: white;
  background-color: black;
  font-family: Arial, sans-serif;
  padding: 20px;
  position: fixed; /* Fixed position to cover the whole screen */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevents scrolling */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timer {
  text-align: center;
  margin-bottom: 30px;
}

.timer h1 {
  margin-bottom: 10px;
}

.timer p {
  font-size: 2em;
  margin-bottom: 20px;
}

.instructions h2 {
  margin-top: 20px;
}

.instructions p {
  margin-top: 10px;
}

.instructions ol {
  margin-top: 10px;
}

button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #333;
  border: none;
  color: white;
  font-size: 1em;
}

button:hover {
  background-color: #555;
}

