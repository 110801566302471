.image-question {
  max-width: 100%;  /* Adjust the width as needed */
  max-height: 420px; /* Adjust the height as needed */
  object-fit: contain;
  display: block;
  margin: 0 auto; /* Center the image */
}

.exam-question {
  position: relative;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: calc(100vh - 20px); /* Full viewport height minus margin */
}  
.header-left, .header-center, .header-right {
  display: flex;
  align-items: center; /* Align items in each section */
  justify-content: center; /* Center content of each section */
}

  .exam-header {
      display: flex;
  	justify-content: space-between; /* This will space out the children */
 	 align-items: center; /* To vertically center the content */
 	 padding: 10px;
  	background-color: #f0f0f0;
 	border-bottom: 1px solid #ccc;
  }
 
.exam-header, .exam-footer {
  flex-shrink: 0; /* Prevent header and footer from shrinking */
}

.header-left, .header-right {
  flex: 1; /* take up equal space */
}

.header-center {
  flex-basis: content; /* size based on content */
}

.exam-content {
  display: flex;
  flex-grow: 1; /* Allow content area to grow and fill available space */
  overflow-y: auto; /* Add scroll if content is taller than available space */
}
  .header-text {
    text-align: left;
  }
  
  .question-side {
    flex: 1;
    padding: 10px;
    border-right: 1px solid #ccc;
  display: inline-table;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

  
  .options-side {
    flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the start */
  overflow-y: auto; /* Enable vertical scroll if content overflows */

  }
  .option-side-response {
    flex: 1;
    padding: 2%;

  }

  .choice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .number-square {
    width: 24px;
    height: 24px;
    background-color: #0074d9;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bookmark-image {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  
  .feedback-id {
    padding-left: 5px;
  }

  .left-section {
    display: flex;
  }

  .center-section {
    display: flex;
    align-items: center;
    display: none;
  }
  
  .right-section .abc-button {
    text-decoration: line-through;
    display: none;
  }
  
  .divider {
    width: 1px;
    background-color: #ccc;
    height: 100%;
    margin: 0 10px;
  }
  
  .passage{
    font-weight: normal;
  }

  .options-list {
    list-style: none;
    padding: 0;
  }
  

  .option {
    position: relative;
    border: 1px solid #000;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
        flex-grow: 1; /* Allow the option to grow and fill available space */
    display: flex;
    justify-content: flex-start; /* Align children to the start (left) */
    align-items: center; /* Center align items vertically */
    padding: 10px;
    min-height: 50px; /* Adjust as needed for consistent height */
    margin-right: 10px;
  }

  .option-letter {
    margin-right: 10px;
    font-weight: bold;
    border: 1px solid #0074d9;
    border-radius: 50%;
    padding: 5px;
    display: inline-block;
  }
  
  .option.selected {
    background-color: #000;
    color: #fff;
  }

  .single-choice-input {
    border-radius: 10px;
    border: 1px solid black; /* Border around the input */
    padding: 5px;
    margin: 10px 0;
    background: transparent;
    width: 60%;
    box-sizing: border-box;
    /* Add padding to the bottom to create space for the underline */
    padding-bottom: 10px;
    background-image: linear-gradient(to right, black, black); /* creates the line */
    background-repeat: no-repeat;
    background-position: left 10px bottom 6px; /* positions the line at the bottom with a small offset */
    background-size: calc(100% - 20px) 1px;
    text-indent: 5px;
    font-size: 20px;
    font-weight: bold;
  }
  

  .button-container {
flex: 1; /* Allow buttons to take up space but align to right */
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Add spacing between buttons */ 
 }

/* Style for the toggle button within the footer */
.toggle-display-questions {
  flex: 1; /* Allow the button to take up space but center it */
  text-align: center;
  padding: 10px 20px;
  margin: 0 auto; /* Center the button */
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* Add any other button styling as needed */
}

 .exam-footer {
  display: flex;
  align-items: center; /* Align items vertically in the middle */
  justify-content: space-between; /* Distribute content horizontally */
  padding: 10px;
  border-top: 1px solid #000;
  }
  .footer-text {
  flex: 1; /* Allow text to take up space but align to left */
  text-align: left;
  }
  
  .exam-footer button {
    background-color: #0074d9; /* Blue background color */
    color: #fff; /* White text color */
    padding: 12px 30px; /* Increase padding for a bigger button */
    border: none;
    border-radius: 50px; /* Make the button more oval */
    cursor: pointer;
    margin-left: 10px; /* Add spacing between buttons */
  }

  .calculator-container {
  position: absolute;
  top: 0; 
  right: 0; 
  z-index: 10; 
    width: 600px; /* same as Desmos calculator */
    margin: 2px; /* optional: to center the calculator */
  }

  .calculator-toggle {
      margin: 5px;
  }
 
.reference-container {
  position: fixed;
  top: 20%;
  left: 20%;
  z-index: 10;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.handle {
  padding: 10px;
  cursor: move;
  width: 100%;
}

.reference-container img {
  max-width: 100%;
  height: auto;
}

.reference-container .exit-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  padding: 5px;
}
  .handle {
    width: 100%; /* handle will take the full width of its parent, which is .calculator-container */
    background: #ddd;
    cursor: move;
    padding: 10px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .menu.collapsed {
    display: none; /* Hide the menu when it is collapsed */
  }
  .question-button {
  display: inline-flex; /* Use flexbox for centering */
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  margin: 5px;
  width: 30px; /* Fixed width */
  height: 30px; /* Fixed height */
  padding: 0; /* Adjust padding to fit the text */
  background-color: white;
  border: 1px dashed black;
  cursor: pointer;
  color: black;
  border-radius: 4px;
  font-size: 14px; /* Adjust font size as needed */
  transition: background-color 0.3s;
}

.question-button.answered {
  background-color: #0074d9;
  border: 1px solid black;
}

.strike-through {
}

.strike-through::after {
  content: '';
  position: absolute;
  left: -10px; /* Extend to the left of the li */
  right: -10px; /* Extend to the right of the li */
  top: 50%; /* Center vertically in the li */
  height: 2px; /* Thickness of the strike-through */
  background: black; /* Color of the strike-through */
  display: block;
}

.strike-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.strike-button {
cursor: pointer;
  width: 10px; /* Adjust size as needed */
  height: 10px; /* Adjust size as needed */
  border-radius: 50%; /* This makes the button round */
  border: 1px solid #ccc; /* Optional: for button outline */
  background-color: #0074d9; /* Optional: background color */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  text-decoration: line-through;
  color: #fff;
}


.strike-button-selected {
    font-size: 12px;
cursor: pointer;
  width: 10px; /* Adjust size as needed */
  height: 10px; /* Adjust size as needed */
  border-radius: 50%; /* This makes the button round */
  border: 1px solid #ccc; /* Optional: for button outline */
  background-color: #0074d9; /* Optional: background color */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  text-decoration: underline;
  color: #fff;
}

.strike-button:hover {
  background-color: #e5e5e5; /* Change background color on hover */
}

.math-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
