/* UserSettings.css */
.user-settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  max-width: 500px;
  margin: auto;
}

.user-settings-header {
  color: #005A9C; /* A shade of blue from the logo */
  text-align: center;
  margin-bottom: 20px;
}

.user-settings-form {
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 20px;
  border-radius: 8px;
}

.user-settings-form div {
  margin-bottom: 15px;
}

.user-settings-form label {
  display: block;
  margin-bottom: 5px;
}

.user-settings-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.user-settings-form button {
  padding: 10px 15px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  margin-right: 15px;
}

.user-settings-form button:hover {
  background-color: #005A9C; /* A slightly darker blue on hover */
}

.user-settings-display {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}

.user-settings-display div {
  margin-bottom: 10px;
}

.user-settings-display button {
  padding: 10px 15px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.user-settings-display button:hover {
  background-color: #005A9C;
}

